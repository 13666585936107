import React from "react";
import Layout from "../components/Layout";
import { BlueCurve1, GrayCurve1 } from "../components/Curves";
import {
  FeaturesWelcomeSection,
  FeaturesDetailsSection,
  FeaturesInfoSection,
  CtaSection,
} from "../sections";

const FeaturesPage = () => {
  return (
    <Layout title="ChatIQ | Features">
      <FeaturesWelcomeSection />
      <FeaturesDetailsSection />
      <FeaturesInfoSection />
      <BlueCurve1 />
      <CtaSection />
      <GrayCurve1 />
    </Layout>
  );
};

export default FeaturesPage;
